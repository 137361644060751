// assets/css/global.scss

// customize some Bootstrap variables
$enable-responsive-font-sizes: true;
$border-radius: 5px;
// the ~ allows you to reference things in node_modules

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  form {
   width: 100% !important;
  }
}
.carousel-control-prev, .carousel-control-next{
  top: unset !important; 
}
.grid:after {
  content: '';
  display: block;
  clear: both;
}
#carouselSliderHome .carousel-control-prev,#carouselSliderHome .carousel-control-next{
  top: 0 !important;
}

#carouselSliderHome .carousel-control-prev-icon ,#carouselSliderHome .carousel-control-next-icon{
  background-color: #17727a;
  padding: 20px;
  border-radius: 50px;
}
/* ---- grid-item ---- */

.grid-sizer,
.grid-item {
  width: 25%;
}

.grid-item {
  height: 440px;
  float: left;
  background-color: #f9f5f0;
  border: 4px solid #f9f5f0;
}
.img-container-masonry {
  height:400px;
}
.img-container-masonry2 {
  height:840px;
}
.grid-alpha{
  display: none;
  position: absolute;
  background-color: #F4F3F4;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
}

.grid-item--width2 {
  width:  50%;
}

.grid-item--height2 {
  height: 880px;
}

.text-masonry{
  display: block;
  height: unset;
  align-self: unset;
  align-items: unset;
}
@media only screen and (max-width: 580px){
  .grid-item{
    width: 50%;
    height: 225px;
  }
  .text-masonry{
    display: flex;
    height: 60px;
    align-self: center;
    align-items: center;
  }
  .grid-item .row{
    padding: 0 !important;
  }
  .grid-item--width2 {
    width:  100%;
  }
  .grid-item .hidden-mobile{
    display: none;
  }
  .hidden-mobile{
    display: none;
  }
  .img-container-masonry {
    height:150px;
  }
  .img-container-masonry2 {
    height:377px;
  }
  .grid-item--height2 {
    height: 450px;
  }
}




#close-cart{
  font-size:20px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 9999999;
}
.featured-card-text{
  position: absolute;
  margin-left: auto;
  padding: 70px 45px;
  margin-right: auto;
  top: 50%;
  width: 60%;
  border-radius: 5px;
  left: 50%;
  color: black;
  background-color: white;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.featured-card{
 height: 450px;
}
.featured-card-image{
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.validerPar{
  bottom: 5px;
  left: 25px;
}
.card-compte-rebour {
  min-width: 50px;
  padding: 10px;
  border-radius: 5px;
  border: 0px !important;
  box-shadow: 4px 4px 2px 0px #d1d1d1;
}
.destockage-overlay{
  z-index: 999 !important;  
}
.destockage-overlay-2{
  z-index: 999 !important;  
  top: 35px;
}
#charteUpload::file-selector-button{
  padding: 10px;
  /* height: 40px; */
  border-radius: 5px;
  background-color: #ededed;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: 1px solid lightgray;
}
#charteUpload{
  border: 1px solid lightgray;
}
.bootstrap-select .dropdown-menu{
  min-width: 100% !important;
}
.w-200{
  width: 200px;
}
.editusernopass .form-check{
  padding-left:0 !important;
}
.editusernopass .form-check-input[type="checkbox"] {
  width: 2.5em;
  height: 2.5em;
  margin-right: 20px;
}
.editusernopass .form-check-label{
  font-size: 22px !important;
}
.editusernopass .form-check-input{
  font-size: 10px;
  bottom: 0;
  left: 20%;
  /* right: 0; */
  /* text-align: left; */
  /* float: left; */
  width: 20px;
}
.text-grey{
  color:grey !important;
}
.m-w-1400{
  max-width: 1400px;
  margin: 0 auto;
}

.m-w-750{
  max-width: 750px;
  margin: 0 auto;
}
.card_field{
  clear: both;
}
.carte-france-somme{
  position: absolute; 
  left:30px;
  top:-150px;
  z-index:999
}
.somme-paralax{
  max-width:100% !important;
  height:500px; 
  background-image:url('/public/uploads/media/photo-groupe-2024.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 70%;
}
.somme-logo-demi{
  position: absolute; 
  bottom:0; 
  right:0; 
  z-index:999;
  width:300px;
}
.m-w{
  max-width: 100% !important;
}
.space-4{
  letter-spacing: 4px !important;
}
.space-3{
  letter-spacing: 3px !important;
}
.top-0{
  top:0 !important;
}
.top-10{
  top:10% !important;
}
.top-20{
  top:20% !important;
}
.top-30{
  top:30% !important;
}
.top-40{
  top:40% !important;
}
.top-50{
  top:50% !important;
}
.top-60{
  top:60% !important;
}
.top-70{
  top:70% !important;
}
.top-80{
  top:80% !important;
}
.top-90{
  top:90% !important;
}
.top-100{
  top:100% !important;
}
.left-0{
  left:0 !important;
}
.left-10{
  left:10% !important;
}
.left-20{
  left:20% !important;
}
.left-30{
  left:30% !important;
}
.left-40{
  left:40% !important;
}
.left-50{
  left:50% !important;
}
.left-60{
  left:60% !important;
}
.left-70{
  left:70% !important;
}
.left-80{
  left:80% !important;
}
.left-90{
  left:90% !important;
}
.left-100{
  left:100% !important;
}
body{
  font-family: 'PT Sans', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}
.trans-white{
  background-color: #0a0a0a4f;
}
.span-button-slider{
  position: absolute;
  z-index: 9;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-caroussel-right{
  position: absolute;
  z-index: 9;
  top: 50%;
  right: 0;
  transform: translate(105%, -50%);
  padding: 50px 10px;
}

.btn-carousel-left{
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  padding: 50px 10px;
}
.button-slider-promotion{
  font-size: 28px;
  text-transform: none;
  padding: 10px 50px;
  background-color: #fff;
  border: 1px solid rgba(202, 202, 202, 0.64);

}
.card-filter{
  border: 1px solid #00a19a;
  background-color:#fff;
}
.card-filter.active{
  border: 1px solid #00a19a;
  background-color:#00a19a;
}
.card-filter.active span{
  color: #fff;
}
.card-filter span{
  color: #00a19a;
}
.add-button{
  float: right;
  border-radius: 5px !important;
}
.card-filter:hover{
  border: 1px solid #00a19a;
  background-color:#00a19a;
}
.card-filter:hover span{
  color: #fff;
}
#perPage select{
  border: 1px solid #d3c5b6 !important;
}
.transform-clear{
  text-transform: unset !important;
}
.text-slider{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.round-border{
  border-radius: 25px !important;
}
.bg-terniaire-opale{
  background: #f5ebdf !important;;
}
.text-terniaire{
  color: #B29F8A !important;
}
.border-terniaire{
 border: 1px solid #B29F8A !important;
}
.new-overlay{
  z-index:999;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 3px;
}
.light-shadow-card{
  box-shadow: 0px 0px 10px 0px #0000002e;
  border-radius: 5px;
}
.promotionValue{
  border-radius: 4px;
  padding: 5px 15px;
  right: 0;
  background-color: black;
  color: white;
  bottom: 0;
  z-index: 999;
  position: absolute;
  margin: 0 !important;

}
.wishlistButton{
  z-index: 999;
  position: absolute;
  right: 0px;
  top: 10px;
  cursor: pointer;
  margin: 0 !important;
  z-index: 999;
}
.bg-etranger{
  background-color: #abf9a9 !important;
}
.pagination .active .page-link{
  background-color: #844d99 !important;
  color: white !important;
  border-color: #844d99 !important;
  border-radius: 5px;
}
#myBtn {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #00a19a;
  color: white;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 41px;
}
.button-fixed{
  bottom: 0;
  position: fixed;
  right: 0;
  border-radius: 4px;
  z-index:999;
  background-color: #00a19ac9 !important;
}
.bottom-text-0{
  bottom:10px;
}
.left-text-0{
  left:0;
}
.back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: none;
}
.pro-link{
  cursor: default;
  color: #8e8e8e !important;
}
.cat-title-product{
  min-height: 50px;
}
.shadow-card-product{
  box-shadow: 1px 1px 2px 1px #efefef;
}
.offcanvas-header{ display:none; }

@media (max-width: 1200px) {
  .offcanvas-header{ display:block; }
  .navbar-collapse {
    position: fixed;
    top:0;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: white;
    transition: visibility .2s ease-in-out, -webkit-transform .2s ease-in-out;
  }
  .navbar-collapse.show {
    visibility: visible;
    transform: translateX(-100%);
  }
}
.img-magnifier-container {
  position:relative;
}
.img-magnifier-glass {
  position: absolute;
  border: 2px solid #adadad;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 125px;
  height: 125px;
}
.preview-thumbnail li a{
  height: 100%;
  width: 100%;
  display: inline-block;
  border: 1px solid #dadada;
}
.preview-thumbnail li a.active{
  border: 1px solid #00a19a;
}
.tab-pane img {
  width: 100%;
  height: 100%;
}
.tab-pane {
  width: 500px;
  height: 500px;
  margin: 0 auto;
}
.f12rem{
  font-size: 1.2rem;
  line-height: 1;
}
.f15rem{
  font-size: 1.5rem;
  line-height: 1;
}
.f20rem{
  font-size: 2rem !important;
  line-height: 1;
}
.height2{
  height: 2px;
}
.w60px{
  width:60px !important;
}
.w150px{
  width:150px !important;
}
.pointer{
  cursor: pointer !important;
}
.f30rem{
  font-size: 3rem !important;
  line-height: 1;
}
.lowercase{
  text-transform: lowercase !important;
}
.f45rem{
  font-size: 4.5rem;
  line-height: 1.2;
}
.semiBorderRight {
  border-right: 2px solid #C5B7A7;
  position: absolute;
  top: 30px;
  bottom: 30px;
  right: 0;
}
.f08rem{
  font-size: 0.8rem;
}
#carouselExampleCaptions{
  margin-top: 130px;
}
.nav-container{
  padding-right: 0 !important;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
#navCloser{
  position: absolute;
  right: 15px;
  top: 15px;
}

.bold{
  font-weight: 900 !important;
}
.underline{
  text-decoration:underline !important;
}
.normal{
  font-weight: 200;
}
.golden-bg{
  background-color: #d8c096;
  margin-top: 130px !important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.text-wishlist{
  color:#bbbbbb;
}
.required-asterisk{
  position: absolute;
  left: 18px;
  font-size: 20px;
  top:-3px;
  z-index: 999;
}
.img-wrapper {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
img {
  max-width: 100%;
  max-height: 100%;
}
@media screen and (min-width: 576px) {
  .carousel-news .carousel-inner {
    display: flex;
  }
  .carousel-news .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 calc(100% / 2);
  }
}
@media screen and (min-width: 950px) {
  .carousel-news .carousel-inner {
    display: flex;
  }
  .carousel-news .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 calc(100% / 4);
  }
}
@media screen and (min-width: 1440px) {
  .carousel-news .carousel-inner {
    display: flex;
  }
  .carousel-news .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 calc(100% / 6);
  }
}
.carousel-news .carousel-inner {
  padding: 1em;
}
.carousel-news .card {
  margin: 0 0.5em;
  border-radius: 0;
  box-shadow: 0px 0px 10px 0px #0000002e;
  font-size: 0.9em;
}

.carousel-news .carousel-control-prev,
.carousel-news .carousel-control-next {
  width: 6vh;
  height: 6vh;
  background-color: #e1e1e1;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.8;
}
.carousel-news .carousel-control-prev:hover,
.carousel-news .carousel-control-next:hover {
  opacity: 0.8;
}
.arrow-carousel{
  position: absolute;
  top: 12px;
  left: 12px;
}
.carousel-right-prev, .carousel-right-next {
    width: auto !important;
    background-color:white;
    padding: 1.2rem 1.2rem;
    box-shadow: 1px 1px 5px 1px #bbbbbb;
    border-radius: 5px;

}
.form-check-input{
  border: 1px solid #b0b0b0;
}

.carousel-right-prev{
    left: unset !important;
    right: 115px !important;
}
.carousel-right-next {
    right: 50px !important;
}
h2, .nav-item {
  font-family: 'PT Sans', serif;
  font-weight: 900;
}
.hide-custom{display: none;}
table input{
  width: 80%;
}
.first-container{
   margin-top:175px; // SANS IMAGE DE PROMOTION OU SOLDES
  // margin-top:210px; // AVEC IMAGE DE PROMOTION OU SOLDES
}
.first-container-product{
  margin-top:250px; // SANS IMAGE DE PROMOTION OU SOLDES
  // margin-top:240px; // AVEC IMAGE DE PROMOTION OU SOLDES
}
.first-container-banner{
  margin-top:175px; // SANS IMAGE DE PROMOTION OU SOLDES
  // margin-top:210px; // AVEC IMAGE DE PROMOTION OU SOLDES
}
.iframeVideo {
	height: 0px;
  padding-left: 0 !important;
	padding-bottom: 56.2%;
	position: relative;
  text-align: center;
}
.iframeVideoAccueil {
	height: 0px;
  padding-left: 0 !important;
	padding-bottom: 560px;
	position: relative;
  text-align: center;
}
.container-banner-text{
  top: 40%;
  text-align: center;
  width: 100%;
  left: 0;
}
.somme-container{
  margin-top:130px;
}
.container-nav{
  max-width: 100% !important;
  padding-left: 25px;
  padding-right: 25px;
}
.bg-light{
  background-color: #f1f1f1 !important;
}
.bg-black{
  background-color: #000 !important;
}
.light-radius{
  border-radius: 3px;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
  list-style: none;
}

#sidebar p{
  margin-bottom: 0 !important;
}
.btn{
  border-radius: 5px !important;
}
.btn-primaire{
  color: #fff !important;
  background-color: #00a19a;
  border-color: #00a19a;
}
.btn:hover{
  color: #fff !important;
  background-color: #00a19a !important;
  border-color: #00a19a !important;
}
.nav-link:hover, #openNav span:hover{
  text-decoration: underline;
}
.nav-item{
  margin: 0 10px;
  font-size: 14px;

}
.account-card i{
  font-size: 3rem;
  color: #00a19a;
}
.account-card p{
  font-size: 0.8rem;
}
.account-card h2, .categorie-card .title-categorie-card{
  font-size: 1.5rem;
}
.account-card, .adresse-card{
  border: 1px solid #dee2e6;
}
.account-card:hover, .adresse-card:hover{
  border: 1px solid black !important;
}
.categorie-card-image{
  width:80%;
}
.categorie-card{
  box-shadow: 0 0 4px 2px #d7d7d7;
}
.categorie-card:hover{
  border-bottom: 3px solid purple;
  box-shadow: 1px 0px 4px 0px #d7d7d7;
}
.navbar.navbar-light .breadcrumb .nav-item.active>.nav-link, .navbar.navbar-light .navbar-nav .nav-item.active>.nav-link{
  background-color:white !important;
  color: #844d99 !important;
  font-weight: bold;
}

.btn-card{
  left: 40px;
  bottom: 40px;
  margin: 0 !important;
}
.btn-transparent-black{
  background-color: rgba(0, 0, 0, 0);
  color: black !important;
  font-weight: bold;
  border: 1px solid black !important;
}
.btn-transparent-white{
  background-color: rgba(0, 0, 0, 0);
  color: white !important;
  font-weight: bold;
  border: 1px solid white !important;
}

.bd-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
  z-index: 1000;
  min-height: calc(100vh);
}
.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #6d7fcc;
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc;
}

#sidebar ul.components {
  border-bottom: 1px solid #dee2e6;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: white;
  background: #4e4eb3;
  text-decoration: none;
}

#sidebar ul li.active>a{
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

#sidebar ul.collapse li a, #sidebar ul.collapsing li a {
  background-color: #4e4eb3 !important;
}
ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: white;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386D5;
}
h2{
  font-family: 'PT Sans', serif !important;
  font-weight: 600 !important;
  font-size: 1.4rem !important;
}
a.article,
a.article:hover {
  background: white !important;
  color: #fff !important;
}
.f45rem{
  font-size: 4.5rem;
  line-height: 1.2;
}
#carouselSliderHome{
  margin-top: 165px;
}
#kbisRegister{
  border: 1px solid #00a19a !important;
  cursor: pointer;
  background-color: #00a19a0f !important;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
}
.sortSelect{
  width: 50%;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (min-width: 992px){
  .hidden-lg{
    display: none;
  }
}
@media (min-width: 992px){
  .hidden-md{
    display: block;
  }
}

@media (max-width: 768px){
  .hidden-sm{
    display: none;
  }
  h1{
    font-size: 1rem !important;
  }
  .f30rem{
    font-size: 1.5rem !important;
  }
  .f15rem{
    font-size: 1rem !important;
  }
}
@media (max-width: 992px){
  .first-container{
    margin-top: 120px !important;
  }
  .container-banner-text{
    top: 20%;
  }
  
.first-container-banner{
  margin-top:100px; // SANS IMAGE DE PROMOTION OU SOLDES
  // margin-top:140px; // AVEC IMAGE DE PROMOTION OU SOLDES
}
  .cart-float-order{
    background-color: white;
    left: -100%;
    position: fixed;
    height: 100vh;
    top: 0;
    overflow: scroll;
  }
  .card-body{    
    padding: 20px 0;
  }
  .card-title{
    font-size:14px !important;
    height: 50px !important;
    line-height: 1.2;
  }
  .card-text{
    font-size: 12px !important;
  }
  .w60px{
    width:50px !important;
  }
  .featured-card-text{
    width: 90% !important;
  }
  #carouselSliderHome{
    margin-top: 140px !important;
  }
  #test-menu-left {
    overflow-y: scroll !important;
    height: calc(100% - 130px) !important;
    -webkit-overflow-scrolling: touch !important;
    width: 100% !important;
}
.slide-menu{
  top: 100px !important;
}
.slide-menu ul {
  width: 100% !important;
  max-width: 100%;
}
  .semiBorderRight {
    border-bottom: 2px solid #C5B7A7;
    position: absolute;
    top: unset;
    bottom: unset;
    right: 30px;
    left: 30px; 
  }   
  .f15rem{
    font-size: 0.7rem !important;
  }
  .f45rem{
    font-size: 2.5rem;
  }
  .cart-float-order.active{
    left: 0 !important;
    top: 0;
    z-index: 99999;
  }
  .hidden-md{
    display: none;
  }
  .categorie-card-image{
    width:30%;
  }
  .sortSelect{
    width: 100%;
  }
  .somme-container{
    margin-top:140px;;
  }
  .tab-pane img, .tab-pane {
    width: 100% !important;
    height: auto !important;
  }
  .galleryImages{
    width: 25% !important;
    padding: 0 !important;
  }
  .carte-france-somme{
    width: 100px;
    left:30px;
    top:-50px;
    z-index:999
  }
  .somme-paralax{
    max-width:100% !important;
    height: 170px; 
    background-image:url('/public/uploads/media/photo_equipe.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;background-size: 100%;
  }
  .somme-logo-demi{
    display: none;
  }
}
@media (max-width: 768px) {
  .slider-accueil{
    margin-top: 185px !important;
  }  
  .iframeVideo iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
  }
  .iframeVideoAccueil iframe {
    width: 100% !important;
    position: absolute;
  }
  .iframeVideo, .iframeVideoAccueil {
    text-align: unset !important;
  }
  
  .logo-video-play{
    left: 37% !important;
    top: 17% !important;
    width: 75px !important;
  }
  .video-demonstration{
    max-width: 100% !important;
    height: 160px !important;
  }

  
  .f45rem{
    font-size: 1.5rem;    
    line-height: 1.2;
  }
  #logo-site{
    height: 50px !important;
  }
  .text-image-bg-primaire{
    bottom: 45% !important;
    padding: 0px 10px !important;
  }
  .text-image-bg-secondaire{
    bottom: 45% !important;
    padding: 0px 10px !important;
  }
  .text-secondaire-image{
    bottom: 27% !important;
    left: 15px !important;
  }
  .btn-card{
    left: 14px;
    bottom: 8px;
    margin: 0 !important;
  }
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
@media (max-width: 992px){
  .hidden-lg{
    display: block;
  }
  .text-slider{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  h5.card-title {
    height: unset !important;
  }
  #cart-right{
    position: fixed;
    background-color: white;
    height: calc( 100% - 120px);
    width: 100% !important;
    right: 0;
    bottom: 0px;
    display: none;
    z-index: 999;
  }
}
.col-centered{
  float: none;
  margin: 0 auto;
}
h5.card-title {
  height: 80px;
}
.disabled-button{
  background: transparent !important;
  border: 1px solid #B29F8A;
  color : #B29F8A;
  cursor: default !important;
}
.disabled-button-active{
  background: transparent !important;
  border: 1px solid #B29F8A;
  color : #B29F8A;
}
.disabled-button-active:hover{
  background: transparent !important;
  border: 1px solid #B29F8A !important;
  color : #B29F8A !important;
}
.disabled-button:hover{
  background: transparent !important;
  border: 1px solid #B29F8A !important;
  color : #B29F8A !important;
  cursor: default !important;
}
.btn:disabled{
  background: transparent !important;
  border: 1px solid #B29F8A !important;
  color : #B29F8A !important;
  opacity: 1 !important;
}
.btn{
  box-shadow: none !important;
}
.bg-primaire{
  background-color: #00a19a !important;
  color: #fff !important;
}
.border-primaire{
  border: 1px solid #00a19a !important;
}
.bg-terniaire2{
  background-color: #F2EDE8 !important;
}
.bg-terniaire{
  background-color: #B29F8A !important;
}
.text-ternaire{
  color: #B29F8A !important;
}
.bg-primaire-dark{
  background-color: #17727A !important;
  color: #fff !important;
}
.bg-secondaire{
  background-color: #844d99 !important;
  color: #fff !important;
}
.text-primaire{
  color: #00a19a !important;
}
.text-secondaire{
  color:#844d99 !important;
}
.container{
  max-width: 1600px;
}
.w-30{
  width: 30% !important;
}
hr.primary{
  border: 1px solid #00a19a;
  margin: 0 !important;
}
hr.secondary{
  border: 5px solid #844d99;
  width: 50px;
}
#cart-title{
  line-height: 3rem;
}
.btn-primaire{
  background-color: #00a19a;
  border-color: #00a19a;
}
.active-connexion{
  border-bottom: 2px solid #00a19a;
}
.btn-secondaire{
  background-color: #844d99;
  border-color: #844d99;
  color: #fff !important;
}
.bg-grey-light{
  background-color : #f6f6f6;
}
.shadow{
  box-shadow: 5px 10px 1rem rgba(0, 0, 0, 0.15) !important;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: white;
  border-bottom: 0;
}
.card{
  box-shadow: none;
  border: 0;
  background-color: white;
}
.commande-information input[type=text], .commande-information input[type=email], .commande-information select{
  margin-bottom: 15px;
  font-weight: normal;
  color: rgb(162, 162, 162);
  margin-left: 10px;
  margin-right: 10px;
}
.commande-information input::placeholder{
  font-weight: normal !important;
  color: rgb(162, 162, 162);
}
/* ---------------------------------------------------
    PANIER
----------------------------------------------------- */


#cart-right{
  position: fixed;
  background-color: white;
  height: calc( 100% - 120px);
  width: 25%;
  right: 0;
  bottom: 0px;
  display: none;
  z-index: 999;
}
#checkout-button{
  width: 100%;
  bottom:0;
  left: 0;
  border-radius: 0 !important;
}
#cart-total{
  width: 100%;
  bottom:0;
  left: 0;
}
#cart-order-product{
  max-height: 450px;
  overflow-y: scroll;
}
@media screen and (max-width: 640px) {
  #cart-right{
    width: 100%;
  }
  .row{
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

/* ---------------------------------------------------
    IMAGE ROLLOVER
----------------------------------------------------- */

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  background: #42b078;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
}

.hovereffect img {
  display: block;
  position: relative;
  max-width: none;
  width: calc(100% + 20px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(-10px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hovereffect:hover img {
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  overflow: hidden;
  padding: 0.5em 0;
  background-color: transparent;
}

.hovereffect h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}

.hovereffect:hover h2:after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect a, .hovereffect p {
  color: #FFF;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

.hovereffect:hover a, .hovereffect:hover p {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.mask{
  background-color: rgba(0, 161, 154, 0.5) !important;
}
.navtop{
  height: 30px;
  padding: 15px;
  line-height: 0px;
  font-size: 12px;
}

.text-image-bg-primaire{
  bottom: 40%;
  padding: 5px 20px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 4px;
  color: white;
  background: rgba(0, 161, 154, 0.7);
}
.text-image-bg-secondaire{
  bottom: 40%;
  padding: 5px 20px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 4px;
  color: white;
  background: rgba(132, 77, 153, 0.7);
}
.text-secondaire-image{
  bottom: 33%;
  left: 60px;
}
.text-primaire-image{
  left: 40px;
  bottom: 100px;
  margin: 0;
}
.font-64{
  font-size: 64px;
}
footer a{
  color: #dadada !important;
}
footer .nav-link{
  padding : 0 !important;
}

a{
  text-decoration: none !important;
}
.megamenucard:hover a{
  cursor: pointer;
  border: 1px solid black !important;
  color: #844d99 !important;
}
.right-menu{
  right: 0 !important;
  left: auto !important;
  height: auto !important;
  border: 0 !important;
  background-color: #fff !important;
  border-radius: 0 !important;
  box-shadow: -1px 2px 5px 0px #d9d9d9 !important;
}
.menuletter:hover{
  color: #00a19a !important;
  cursor: pointer;
}
p.menuletter {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 4px;
}

.listepierre{
  overflow-y: scroll;
}
.spinner {
  position: absolute;
  left: 50%;
  top: 40%;
  margin:0px auto;
}

.irs--round .irs-handle{
  border: 4px solid #00a19a !important;
}
.irs--round .irs-bar{
  background-color: #00a19a !important;
}
.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single{
  background-color: #00a19a !important;
}
.icon-rs{
  font-size: 40px;
  color: #844d99;
}
ul ul a{
  background-color: white !important;
}
.nav-item a:hover, li.show{
  text-decoration: underline !important;
}
#scanFacture:focus{
  border: none !important;
}

.jumbotron {
  background: #6b7381;
  color: #bdc1c8;
}
.jumbotron h1 {
  color: #fff;
}
.example {
  margin: 4rem auto;
}
.example > .row {
  margin-top: 2rem;
  height: 5rem;
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgba(189, 193, 200, 0.5);
}
.example > .row:first-of-type {
  border: none;
  height: auto;
  text-align: left;
}
.example h3 {
  font-weight: 400;
}
.example h3 > small {
  font-weight: 200;
  font-size: 0.75em;
  color: #939aa5;
}
.example h6 {
  font-weight: 700;
  font-size: 0.65rem;
  letter-spacing: 3.32px;
  text-transform: uppercase;
  color: #bdc1c8;
  margin: 0;
  line-height: 5rem;
}
.example .btn-toggle {
  top: 50%;
  transform: translateY(-50%);
}
.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}
.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.btn-toggle.active:before {
  opacity: 0.5;
}
.btn-toggle.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}
.btn-toggle.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  display: none;
}
.btn-toggle:before,
.btn-toggle:after {
  color: #6b7381;
}
.btn-toggle.active {
  background-color: #29b5a8;
}
.btn-toggle.btn-sm:before {
  content: 'Off';
  left: -0.5rem;
}
.btn-toggle.btn-sm:after {
  content: 'On';
  right: -0.5rem;
  opacity: 0.5;
}
.btn-toggle.btn-lg {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 2.5rem;
  width: 5rem;
  border-radius: 2.5rem;
}
.btn-toggle.btn-lg:focus,
.btn-toggle.btn-lg.focus,
.btn-toggle.btn-lg:focus.active,
.btn-toggle.btn-lg.focus.active {
  outline: none;
}
.btn-toggle.btn-lg:before,
.btn-toggle.btn-lg:after {
  line-height: 2.5rem;
  width: 5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.btn-toggle.btn-lg > .handle {
  position: absolute;
  top: 0.3125rem;
  left: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 1.875rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.btn-lg.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-lg.active > .handle {
  left: 2.8125rem;
  transition: left 0.25s;
}
.btn-toggle.btn-lg.active:before {
  opacity: 0.5;
}
.btn-toggle.btn-lg.active:after {
  opacity: 1;
}
.btn-toggle.btn-lg.btn-sm:before,
.btn-toggle.btn-lg.btn-sm:after {
  line-height: 0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.6875rem;
  width: 3.875rem;
}
.btn-toggle.btn-lg.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-lg.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-lg.btn-xs:before,
.btn-toggle.btn-lg.btn-xs:after {
  display: none;
}
.btn-toggle.btn-sm {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
}
.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active {
  outline: none;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: 1.5rem;
  width: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.55rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.btn-toggle.btn-sm > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.btn-sm.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-sm.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.btn-toggle.btn-sm.active:before {
  opacity: 0.5;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm.btn-sm:before,
.btn-toggle.btn-sm.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}
.btn-toggle.btn-sm.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm.btn-xs:before,
.btn-toggle.btn-sm.btn-xs:after {
  display: none;
}
.btn-toggle.btn-xs {
  margin: 0 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1rem;
  width: 2rem;
  border-radius: 1rem;
}
.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus.active,
.btn-toggle.btn-xs.focus.active {
  outline: none;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  line-height: 1rem;
  width: 0;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.btn-toggle.btn-xs > .handle {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.btn-xs.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-xs.active > .handle {
  left: 1.125rem;
  transition: left 0.25s;
}
.btn-toggle.btn-xs.active:before {
  opacity: 0.5;
}
.btn-toggle.btn-xs.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs.btn-sm:before,
.btn-toggle.btn-xs.btn-sm:after {
  line-height: -1rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.275rem;
  width: 1.55rem;
}
.btn-toggle.btn-xs.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-xs.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs.btn-xs:before,
.btn-toggle.btn-xs.btn-xs:after {
  display: none;
}
.btn-toggle.btn-secondary {
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle.btn-secondary:before,
.btn-toggle.btn-secondary:after {
  color: #6b7381;
}
.btn-toggle.btn-secondary.active {
  background-color: #ff8300;
}
.interrupteur-pro{
  position: fixed;
  right: -50px;
  top: 50%;
  transform: rotateZ(
                  -90deg
  );
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.addGestion .input-group{
  margin: 6px 0px;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.custom-control-input{
  opacity: 1 !important;
}
$sm-menu-width: 320px !default;
$sm-menu-bg: #333 !default;

$sm-link-color: #45ADA8 !default;
$sm-link-padding: 12px 24px !default;

$color-primary: #fff;
$sm-transition-dur: 300ms !default;
$sm-transition-easing: ease-in-out !default;


@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}
@media (max-width: 992px){
  .slide-menu {
    position: fixed;
    width: $sm-menu-width;
    max-width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    display: none;
    overflow: hidden;
    box-sizing: border-box;
    background: $sm-menu-bg;
    transition: transform $sm-transition-dur $sm-transition-easing;
    transform: translateX(100%);
    will-change: transform;
    z-index: 9999;
  }
}

.slide-menu {
  position: fixed;
  width: $sm-menu-width;
  max-width: 100%;
  height: 100vh;
  top: 146px;
  right: 0;
  display: none;
  overflow: hidden;
  box-sizing: border-box;
  background: $sm-menu-bg;
  transition: transform $sm-transition-dur $sm-transition-easing;
  transform: translateX(100%);
  will-change: transform;
  z-index: 1000;

  .slider {
    top: 0;
    left: 0;
    width: 100%;
    transition: transform $sm-transition-dur $sm-transition-easing;
    transform: translateX(0);
    will-change: transform;
  }

  ul {
    @include list-unstyled;

    position: relative;
    width: $sm-menu-width;
    max-width: 100%;
    margin: 0;

    ul {
      position: absolute;
      top: 0;
      left: 100%;
      display: none;
    }

    a {
      display: block;
      padding: $sm-link-padding;
      color: #656f84;
      font-weight: bold;
      text-decoration: none;
      font-size: 1rem;
    }
  }

  &.no-transition, &.no-transition * {
    transition: none !important;
  }
}
#test-menu-left{
  overflow-y: scroll;
  height: calc(100% - 146px);
  -webkit-overflow-scrolling: touch;
}
.border-menu{
  border-bottom: 1px solid #dee2e6 !important;
}
.slide-menu {
  background: $color-primary;
  color: #fff;

  .controls {
    overflow: hidden;
  }
  ul{
    li{
      border-bottom:1px solid #f8f9fa;
      &:first-child{
        border-top:1px solid white;
      }
    }
  }

  a {
    color: #fff;
    background-color: $color-primary;
    border-bottom: 1px solid #dee2e6;
    &:hover {
      background-color: $color-primary;
    }
  }

  .btn {
    float: right;
    color: #fff;
    background: darken($color-primary, 12%);
    padding: 8px;

    &:hover {
      background: darken($color-primary, 12% + 6%);
    }

    & + .btn {
      margin-right: 1px;
    }
  }

}
.btn-carousel-left{
  position: absolute;
  right: 55px;
  top: 0;
  line-height: 1;
  padding: 0.8rem 1rem !important;
  left: unset;
  transform: unset;
  border-radius: 5px !important;
  font-size: 1rem !important;
  box-shadow: 1px 1px 2px black !important;
}
.btn-carousel-right{
  position: absolute;
  right: 0px;
  top: 0;  
  line-height: 1;
  padding: 0.8rem 1rem !important;
  left: unset;
  transform: unset;
  border-radius: 5px !important;
  font-size: 1rem !important;
  box-shadow: 1px 1px 2px black !important;
}
.carousel-box{
  gap: 10px;
}


.slide-menu-control{
  color: #00a19a !important;
}
.overlay-pierre{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top:70%;
  width: 100%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(249, 245, 240, 0.5);
}
.overlay-menu{
  background-color: rgba(0, 0, 0, 0.54);
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  top: 0px;
  left: 0px;
  display:none;
}
.menu-element{
  a {
    display: block;
    padding: $sm-link-padding;
    color: $sm-link-color;
    text-decoration: none;
  }
  span  {
    display: block;
    padding: $sm-link-padding;
    color: $sm-link-color;
    text-decoration: none;
  }
}

.menu:focus{
  outline: none !important;
}
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: #00a19a !important;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.pagination-mea ul{
  float:right;
}
.container-pierre img{
  max-width: 100% !important;
}
@media (max-width: 768px) {
  .container-pierre img{
    height: auto !important;
  }
  .overlay-pierre{
    top:50% !important;
  }
  .titre-page-pierre{
    font-size: 16px !important;
  }
  .btn-page-pierre{
    font-size: 12px !important;
  }
  .menu-element span {
    padding: 12px 5px !important;
  }
}

.container-pierre h2{
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  color: black;
}
.container-pierre h3{
  font-size: 1rem;
  font-weight: bold;
  color: #16a085;
}
#menu-desktop-left{
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  padding-top: 130px;
  height: 100%;
  width: 150px;
}
.slider-accueil{
  margin-top: 145px;
}
#logo-site{
  height: 100px;
}
#menuPrincpal{
  position: fixed;
  padding-bottom: 150px;
  top: 175px; // NORMAL
  // top: 210px; // SOLDES
  left: 0;
  z-index: 999;   
  background-color: white;
  width: 20%;
  height:calc(100% - 131px);
  display:none;
  overflow-y: scroll;
}
.secondMenu{
  position: fixed;  
  top: 175px; // NORMAL
  // top: 210px; // SOLDES
  left: 20%;
  z-index: 999;
  background-color: white;
  width: 20%;
  height: calc(100% - 175px);
  display: none;
  overflow-y: scroll;
}
.sublevelIndicator{
  position: absolute;
  right: 0;
  top: 0;
  font-size: 26px;
}
.thirdMenu{
  position: fixed;
  top: 175px; // NORMAL
  // top: 210px; // SOLDES
  left: 40%;
  z-index: 999;
  background-color: white;
  width: 20%;
  height:calc(100% - 175px);
  display:none;
  overflow-y: scroll;
}
.quatreMenu{
  position: fixed;
  top: 175px; // NORMAL
  // top: 210px; // SOLDES
  left: 60%;
  z-index: 999;
  background-color: white;
  width: 20%;
  height:calc(100% - 175px);
  display:none;
  overflow-y: scroll;
}
.cinqMenu{
  position: fixed;
  top: 175px; // NORMAL
  // top: 210px; // SOLDES
  left: 80%;
  z-index: 999;
  background-color: white;
  width: 20%;
  height:calc(100% - 175px);
  display:none;
  overflow-y: scroll;
}
#menuPrincpal .menu-element a:hover, .secondMenu .menu-element a:hover, .thirdMenu .menu-element a:hover, .quatreMenu .menu-element a:hover, .cinqMenu .menu-element a:hover, #menuPrincpal .menu-element a.active, .secondMenu .menu-element a.active, .thirdMenu .menu-element a.active, .quatreMenu .menu-element a.active, .cinqMenu .menu-element a.active{
  background-color: #f1f1f1 !important;
  color: #00a19a !important;
}
#menuPrincpal .menu-element a, .secondMenu .menu-element a, .thirdMenu .menu-element a, .quatreMenu .menu-element a, .cinqMenu .menu-element a{
  display: flex;
  align-items: center;
}
#menuPrincpal .menu-element a img, .secondMenu .menu-element a img, .thirdMenu .menu-element a img, .quatreMenu .menu-element a img, .cinqMenu .menu-element a img{
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
}
.menu-mobile-item{
  position: relative;
}

#test-menu-left .menu-element a:hover, #test-menu-left .menu-element a.active{
  background-color: #f1f1f1 !important;
  color: #00a19a !important;
}
#test-menu-left .menu-element a{
  display: flex !important;
  align-items: center;
}
#test-menu-left .menu-element a img{
  max-width: 50px;
  max-height: 50px;
}
#social_side_links {
  position: fixed;
  top: 50%;
  right: 0;
  padding: 0;
  list-style: none;
  z-index: 99;
}
.hr-middle{
  display: block;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

#social_side_links li a {display: block;}

#social_side_links li a img {
  display: block;
  max-width:50px;
  -webkit-transition:  background .2s ease-in-out;
  -moz-transition:  background .2s ease-in-out;
  -o-transition:  background .2s ease-in-out;
  transition:  background .2s ease-in-out;
}

#social_side_links li a:hover img {background: rgba(0, 0, 0, .2);}
.footer-social img{
  width: 40px;
  margin-right: 5px !important;
}
#currency-field::placeholder{
  color: #a2a2a2;
}
#currency-field{
  color: black;
}
.search-link:hover{
  background-color: white;
}
.search-link:hover{
  background-color: rgb(177, 177, 177) !important;
}
#searchAutocomplete{
  position: absolute;
  top: 65px;
  width: 100%;
  left: 0;
  margin: 0 !important;
  background-color: white;
  box-shadow: -1px 3px 6px 0px #7d7d7d;
  border-radius: 2px;
  max-height: 600px;
  min-height: 600px;
  z-index: 999;
}
.border-lightgrey-custom{
  border-right: 1px solid #d9d9d9;
}